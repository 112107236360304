
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { InsumoService } from "@/core/services/orcamentoObras";
import { gantt } from 'dhtmlx-gantt';

@Component
export default class CadastroInsumo extends Vue {
  @Prop() public tasks!: any;
  @Prop() public value!: string;

    data = [];
    links = []

    $_initGanttEvents() {
       if (!gantt.$_eventsInitialized) {
        gantt.attachEvent('onTaskSelected', (id) => {
          let task = gantt.getTask(id);
          this.$emit('task-selected', task);
        });

        gantt.attachEvent('onTaskIdChange', (id, new_id) => {
          if (gantt.getSelectedId() == new_id) {
            let task = gantt.getTask(new_id);
            this.$emit('task-selected', task);
          }
        });
        gantt.config.scale_unit = "month";
        gantt.$_eventsInitialized = true;
       }
    }

     $_initDataProcessor() {
      if (!gantt.$_dataProcessorInitialized) {
        gantt.createDataProcessor((entity, action, data, id) => { 
          this.$emit(`${entity}-updated`, id, action, data);
        });

        gantt.$_dataProcessorInitialized = true;
      } 
    }

  service = new InsumoService();
 
  valid = true;
  dialog = false;
  tab = null;

  fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

  $refs!: {
    form: HTMLFormElement;
    ganttContainer:HTMLDivElement;
  }; 

  @Watch("item")
  Item() {
    if (this.$refs.form) {
      this.$refs.form.resetValidation(); 
    } 
  }
 
  mounted(){
    this.$_initGanttEvents();
    gantt.config.date_format = "%Y-%m-%d";
    gantt.init(this.$refs.ganttContainer);
    gantt.config.readonly = true;

    gantt.config.columns = [
      {name:"text",       label:"Etapa",  width:"*", tree:true },
      {name:"start_date", label:"Data de Inicio", align:"center" },
      {name:"duration",   label:"Duração",   align:"center" },
    ]; 
    
    gantt.config.scale_unit = "month";
    gantt.config.date_scale = "%F, %Y";
    gantt.i18n.setLocale("pt");
    
    gantt.config.subscales = [
      {unit:"month", step:2, date:"%d"}
    ];

    gantt.parse(this.tasks);
    this.$_initDataProcessor()
  }
}
